<template>
  <div class="roleDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.role.id ? '编辑' : '新增' }}角色</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="ID" v-if="data.role.id">
        <el-input v-model="data.role.id" disabled />
      </el-form-item>
      <el-form-item label="角色名称">
        <el-input v-model="data.role.name" />
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="菜单">
            <el-card style="width: 100%;max-height: calc(100vh - 350px); overflow: auto;" shadow="hover">
              <el-tree ref="menusRef" :data="show.menus" :props="{ label: 'label', children: 'pages' }" :default-checked-keys="show.menusDefault" node-key='path' show-checkbox />
            </el-card>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="权限">
            <el-card style="width: 100%;max-height: calc(100vh - 350px); overflow: auto;" shadow="hover">
              <el-tree ref="authsRef" :data="show.auths" :props="{ label: 'label' }" :default-checked-keys="show.authsDefault" node-key='label' show-checkbox />
            </el-card>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import menusConf from '../../router/menusConf'

const menus = menusConf.filter(false)

const router = useRouter()
const menusRef = ref()
const authsRef = ref()
const show = reactive({
  menus: menus,
  menusDefault: [],
  auths: [],
  authsDefault: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "roleDetail")
if (pageParam) {
  show.menusDefault = pageParam.menuList || []
  show.authsDefault = pageParam.authList || []
}
const data = reactive({
  role: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {}
})

api.get('/backend/role/getAllAuth').then(res => {
  show.auths = res.authList.map(a => { return { 'label': a } })
})

const save = () => {
  const selectedMenus = menusRef.value.getCheckedNodes().filter(m => m.path).map(m => m.path)
  const selectedAuths = authsRef.value.getCheckedNodes().map(m => m.label)
  
  data.role.menuList = selectedMenus
  data.role.authList = selectedAuths

  if (!data.role.id) {
    api.post('/backend/role/add', data.role).then(() => {
      ElMessage.success('保存成功')
      router.go(-1)
    })
  } else {
    api.post('/backend/role/setById', data.role).then(() => {
      ElMessage.success('保存成功')
      router.go(-1)
    })
  }
}

</script>

<style lang="less">
.roleDetail {
  margin: auto;
  max-width: 900px;
}
</style>